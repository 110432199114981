import './App.scss'

function Overlay() {
  return(
    <div className="overlay">
      <div className="centered-content">
        <h1>Yes</h1>
      </div>
    </div>
  )
}

export default Overlay;